import React, { useContext } from "react"
import image from "../images/celebration.svg"
import { UserContext } from "../contexts/UserContext"

function Gratitude() {
    const userContext = useContext(UserContext)

    return (
        <>
            <h1 className="evo-home-welcome-title">We appreciate your donation</h1>
            <div className="evo-home-welcome">
                <img alt="" className="evo-home-welcome-img" src={image} />
                <h2>We couldn't make it without you, {userContext?.userDetails} :-)</h2>
                <div className="evo-home-welcome-desc">“Thanks to your donation the improvement of the sample platform is possible”</div>
            </div>
        </>
    );
}

export default Gratitude;