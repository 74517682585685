import React from "react"
import { Link } from "react-router-dom";

function Aside({ styles, tabs }) {
    return (
        <aside className={styles["evo-aside-container"]}>
            <nav className={styles["evo-aside-navigation"]}>
                <Link className={`${styles["evo-aside-btn"]} ${tabs.home}`} to="/">
                    <i className="fas fa-house-user"></i>
                    <span>Home</span>
                </Link>
                <div className={styles["evo-aside-divider"]}></div>
                <Link className={`${styles["evo-aside-btn"]} ${tabs.emails}`} to="/emails">
                    <i className="fas fa-envelope"></i>
                    <span>Emails</span>
                </Link>
                <Link className={`${styles["evo-aside-btn"]} ${tabs.notification}`} to="/notifications-push">
                    <i className="fas fa-bell"></i>
                    <span>Push notifications</span>
                </Link>
                <Link className={`${styles["evo-aside-btn"]} ${tabs.thumbnails}`} to="/thumbnails">
                    <i className="fas fa-image"></i>
                    <span>Thumbnails</span>
                </Link>
                <Link className={`${styles["evo-aside-btn"]} ${tabs.variants}`} to="/variants-products">
                    <i className="fas fa-tags"></i>
                    <span>Product variants</span>
                </Link>
                <Link className={`${styles["evo-aside-btn"]} ${tabs.webhooks}`} to="/webhooks">
                    <i className="fas fa-assistive-listening-systems"></i>
                    <span>Webhooks</span>
                </Link>
            </nav>
            <div className={styles["evo-aside-donate-container"]}>
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="hosted_button_id" value="ZEYL9RRGXE5TG" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                </form>
            </div>
        </aside>
    )
}

export default Aside