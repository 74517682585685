import React, { useContext, useState } from "react"
import { BackdropContext } from "../contexts/BackdropContext"
import image from "../images/result.svg"
import Backdrop from "../components/Backdrop"
import VariantesFormContainer from "../containers/VariantesFormContainer"

function VariantesProductos() {
    const { showBackdrop } = useContext(BackdropContext)
    const [backdropVariantesForm, setBackdropVariantesForm] = useState("")
    
    return (
        <>
            <h1 className="evo-home-welcome-title">Product variants</h1>
            <div className="evo-home-welcome">
                <img alt="" className="evo-home-welcome-img" src={image} />
                <h2>Welcome to Product variants :-)</h2>
                <div className="evo-home-welcome-desc">¡Start exploring our product variants tool!</div>
                <div className="evo-home-welcome-accions">
                    <button className="evo-btn evo-btn-primary" onClick={e => showBackdrop(backdropVariantesForm, setBackdropVariantesForm, e)}>Create product variant</button>
                    <a className="evo-btn evo-btn-primary" href="https://docs.evospike.com/en/latest/tools-evopsike/product-variations/" target="_blank" rel="noreferrer">Documentation</a>
                </div>
            </div>
            <Backdrop 
                title="Add new variant"
                backdrop={backdropVariantesForm}
                showBackdrop={e => showBackdrop(backdropVariantesForm, setBackdropVariantesForm, e)}
                bodyPresentation={<VariantesFormContainer backdrop={backdropVariantesForm} setBackdropVariantesForm={setBackdropVariantesForm} />}
            />    
        </>
    )
}

export default VariantesProductos;