import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getTokenAsync, onMessageListenner } from "../firebase-config"
import UserProvider from "../contexts/UserContext"
import BackdropProvider from "../contexts/BackdropContext"
import WebhookProvider from "../contexts/WebhookContext"
import EmailProvider from "../contexts/EmailContext"
import Layout from './Layout';
import Login from '../pages/Login'
import Home from '../pages/Home'
import Emails from '../pages/Emails'
import Notificaciones from '../pages/Notificaciones'
import Thumbnails from "../pages/Thumbnails"
import VariantesProductos from '../pages/VariantesProductos'
import Webhooks from '../pages/Webhooks'
import Gratitude from '../pages/Gratitude'
import NotFound from '../pages/NotFound'
import '../styles/site.css'

function App() {
  getTokenAsync().then(currentToken => {
    console.log(currentToken)
  })
  onMessageListenner().then(payload => {
    new Notification(payload.notification.title, { body: payload.notification.body })
  })

  if(JSON.parse(localStorage.getItem("darkmode")) === null || JSON.parse(localStorage.getItem("darkmode")) === true){
    document.body.classList.remove("evo-light-mode")}
  else
    document.body.classList.add("evo-light-mode")

  return (
    <UserProvider>
      <BackdropProvider>
        <WebhookProvider>
          <EmailProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="emails" element={<Emails />} />
                <Route path="notifications-push" element={<Notificaciones />} />
                <Route path="thumbnails" element={<Thumbnails />} />
                <Route path="variants-products" element={<VariantesProductos />} />
                <Route path="webhooks" element={<Webhooks />} />
                <Route path="donation-gratitude" element={<Gratitude />} />
                <Route path="donation-cancel" element={<Navigate replace to="/" />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </EmailProvider>
        </WebhookProvider>
      </BackdropProvider>
    </UserProvider>
  )
}

export default App
