import React from "react"
import PropTypes from 'prop-types';

function Modal({ title, modalToggle, showBackdrop, bodyPresentation, onAdd }) {
    return (
        <div className={`evo-overlay-backdrop ${modalToggle}`} onClick={showBackdrop}>
            <div className="evo-modal-presentation">
                <div className="evo-modal-presentation-title">
                    <h2>{title}</h2>
                    <button onClick={showBackdrop}><i className="fas fa-times" style={{zIndex: "-1"}}></i></button>
                </div>
                <div className="evo-modal-presentation-body">
                    {bodyPresentation}
                </div>
                <div className="evo-modal-presentation-footer">
                    <button type="button" className="evo-btn evo-btn-primary" onClick={onAdd}>Add</button>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    bodyPresentation: PropTypes.element
}

export default Modal