import React from "react"
import PropTypes from 'prop-types';

function Backdrop({ title, backdrop, showBackdrop, bodyPresentation, btnText, onClick }) {
    return (
        <div className={`evo-overlay-backdrop ${backdrop}`} onClick={showBackdrop}>
            <div className="evo-form-presentation">
                <div className="evo-form-presentation-title">
                    <h2>{title}</h2>
                    <button onClick={showBackdrop}><i className="fas fa-times" style={{zIndex: "-1"}}></i></button>
                </div>
                <div className="evo-form-presentation-body">
                    {bodyPresentation}
                </div>
                {btnText !== undefined && 
                    <div className="evo-form-presentation-footer">
                        <button className="evo-btn evo-btn-primary" onClick={onClick}>{btnText}</button>
                    </div>
                }
            </div>
        </div>
    )
}

Backdrop.propTypes = {
    bodyPresentation: PropTypes.element
}

export default Backdrop