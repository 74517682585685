import React, { useState, useEffect, useContext } from "react"
import { BackdropContext } from "../contexts/BackdropContext"
import Modal from "./Modal"
import VariantesImages from "./VariantesImages"

function VariantesTable({ inputs, removeVariant, addCombinacionesImagenes, setBackdropVariantesForm }) {
    const { showBackdrop } = useContext(BackdropContext)
    const [modalVariantesImages, setModalVariantesImages] = useState("")
    const [combinacionKey, setCombinacionKey] = useState("")
    const [combinacionSelected, setcombinacionSelected] = useState("")
    const [variantes, setVariantes] = useState(() => mapearVariantes(inputs, formatSKU))
    
    useEffect(() => {
        setVariantes(mapearVariantes(inputs, formatSKU))
    }, [inputs])

    const onCombinacionSelected = index => {
        setcombinacionSelected(index)
    }

    const onAddCombinacionSelected = () => {
        addCombinacionesImagenes(combinacionSelected, combinacionKey)
        setModalVariantesImages("")
    }

    const handleChange = (index, e) => {
        let {name, value} = e.target

        if(!value)
            value = 0
        if(!isNaN(Number(value)))
            value = parseInt(value)

        setVariantes(values => values.map((variante, i) => i === index ? {...variante, [name]: value} : variante))
    }

    const sendVariantes = e => {
        e.preventDefault()
        const jsonData = {
            ProductoID: inputs.tb_producId,
            Cantidad: parseInt(inputs.tb_cantidad),
            Imagenes: inputs.imagenes,
            Variantes: [],
        }

        variantes.forEach(variante => {
            jsonData.Variantes.push({
                SKU: variante.SKU,
                CaracteristicasAtributos: variante.CaracteristicasAtributos,
                VarianteTypo: variante.VarianteTypo,
                Imagen: variante.Imagen,
                Cantidad: variante.Cantidad
            })
        })

        jsonData.Cantidad = variantes.map(variante => variante.Cantidad).reduce((prev, current) => prev + current)
        fetch(inputs.webhookUrl, {
            method: "post", 
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
        
        setBackdropVariantesForm("")
    }

    return (
        <>
            <div className="evo-form-row">
                <div className="evo-form-group evo-form-group-hr">
                    <label>Variants</label>
                    <span style={{fontSize: ".8rem", color: "#989898", marginBottom: ".5rem", display: "block"}}>Assign all images first before modifying variants</span>
                    <table id="table_preview">
                        <thead>
                            <tr>
                                <th>SKU</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                variantes.map((variante, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="evo-row-textbox">
                                                <input type="text" className="evo-textbox-style" name="SKU" value={variante.SKU} onChange={e => handleChange(index, e)} />
                                            </div>
                                        </td>
                                        <td>
                                            <span>{variante.VarianteTypo}</span>
                                        </td>
                                        <td>
                                            <div className="evo-row-textbox">
                                                <input type="number" className="evo-textbox-style" name="Cantidad" value={variante.Cantidad} onChange={e => handleChange(index, e)} />
                                                <span className="evo-fontawesome-icon" title="Delete" onClick={() => removeVariant(index)}>
                                                    <i className="fas fa-trash"></i>
                                                </span>
                                                <div className="evo-row-flex-inTable">
                                                    <div 
                                                        className="evo-imagenes-upload" 
                                                        title="Images" 
                                                        onClick={e => {showBackdrop(modalVariantesImages, setModalVariantesImages, e); setCombinacionKey(index)}}
                                                        style={{backgroundImage: variante.BlobUrl }}>
                                                        <i className="fas fa-cloud-upload-alt"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>
                                    <span id="sp_countProducts">Total: {variantes.map(variante => variante.Cantidad).reduce((prev, current) => prev + current)}</span>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div className="evo-form-row">
                <div className="evo-form-group">
                    <button className="evo-btn evo-btn-primary" style={{ marginLeft: "0"}} onClick={sendVariantes}>Submit variants</button>
                </div>
            </div>
            <Modal 
                title="Select Variant Image"
                modalToggle={modalVariantesImages}
                showBackdrop={e => showBackdrop(modalVariantesImages, setModalVariantesImages, e)}
                bodyPresentation={<VariantesImages imagenes={inputs.blobUrls} onCombinacionSelected={onCombinacionSelected} modalVariantesImages={modalVariantesImages} />}
                onAdd={onAddCombinacionSelected}
            />
        </>
    )
}

function mapearVariantes(inputs, formatSKU) {
    const variantesMap = inputs.combinaciones.map((combinacion, index) => {
        return {
            SKU: inputs.tb_producId + "-" + formatSKU(index + 1),
            CaracteristicasAtributos: combinacion.map(json => json),
            VarianteTypo: combinacion.map(json => json.ValorId).join(" • "),
            Imagen: `${inputs.combinacionesImagenes.map(c => c.combinacionKey).includes(index) ? inputs.combinacionesImagenes.find(c => c.combinacionKey === index).imagen : ""}`,
            BlobUrl: `${inputs.combinacionesImagenes.map(c => c.combinacionKey).includes(index) ? `url(${inputs.combinacionesImagenes.find(c => c.combinacionKey === index).blobUrl})` : ""}`,
            Cantidad: inputs.tb_cantidad <= 0 ? 1 : Math.ceil(inputs.tb_cantidad / inputs.combinaciones.length)
        }
    })

    return variantesMap
}

function formatSKU(num) {
    let str = "" + num;
    let pad = "000";
    return pad.substring(0, pad.length - str.length) + str;
}

export default VariantesTable