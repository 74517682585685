import React from "react"
import webhookAction from "../actions/WebhookAction"

function WebhookList({ webhookUrls, dispatch, triggerShowBackdrop, onHandleSearch, userContext, triggerShowBackdropEdit }) {
    return (
        <>
            <div className="evo-table-header">
                <div className="evo-table-header-item">
                    <h2>Webhooks</h2>
                </div>
                <div className="evo-table-header-item evo-table-header-item-search">
                    <i className="fas fa-search"></i>
                    <input type="search" className="evo-input-search" onChange={onHandleSearch} placeholder="Search" />
                </div>
                <div className="evo-table-header-item evo-table-header-item-last">
                    <button className="evo-btn evo-btn-primary" onClick={e => triggerShowBackdrop(e)}>Create webhook</button>
                    <a className="evo-btn evo-btn-primary" href="https://docs.evospike.com/en/latest/tools-evopsike/webhooks/" target="_blank" rel="noreferrer">Documentation</a>
                </div>
            </div>
            <div className="evo-table-container">
                <div className="evo-table-body">
                    <table>
                        <thead>
                            <tr>
                                <th>Webhook url</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                webhookUrls?.map((webhookUrl, index) => (
                                    <tr key={index}>
                                        <td>{webhookUrl}</td>
                                        <td>
                                            <button className="evo-btn evo-btn-secondary" onClick={e => triggerShowBackdropEdit(webhookUrl, e)}>Edit</button>
                                            <button className="evo-btn evo-btn-danger" onClick={() => dispatch({ type: webhookAction.DELETE_WEBHOOK, payload: {webhookUrl: webhookUrl, userId: userContext?.userDetails} })}>Delete</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default WebhookList