import React from "react"
import styles from "../styles/aside.module.css"
import { useLocation } from "react-router-dom";
import Aside from "../components/Aside";

function AsideContainer() {
    const location = useLocation()
    const { pathname } = location
    const splitLocation = pathname.split("/")
    const tabs = { home: "", emails: "", notification: "", thumbnails: "", variants: "", webhooks: "" }
    
    switch(splitLocation[1]) {
        case "":
            tabs.home = styles.active
            break
        case "emails":
            tabs.emails = styles.active
            break
        case "notifications-push":
            tabs.notification = styles.active
            break
        case "thumbnails":
            tabs.thumbnails = styles.active
            break
        case "variants-products":
            tabs.variants = styles.active
            break
        case "webhooks":
            tabs.webhooks = styles.active
            break
        default:
            tabs.home = styles.active
            break
    }

    return <Aside {...{ styles, tabs }}/>
}

export default AsideContainer