import React from "react"

function WebhookForm({ inputRef, webhook, onHandleChange }) {
    return (
        <form onSubmit={e => e.preventDefault()} autoComplete="off">
             <div className="evo-form-row">
                <div className="evo-form-group">
                    <label>Webhook Url</label>
                    <input 
                        type="url" 
                        className="evo-form-input-text" 
                        ref={inputRef} 
                        name="webhookUrl" 
                        value={webhook.webhookUrl}
                        onChange={onHandleChange} 
                        placeholder="https://www.urlprueba.com/api/productos/variantes" 
                        required 
                    />
                </div>
            </div>
        </form>
    )
}

export default WebhookForm