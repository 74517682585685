import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyA2tqmJrSbKOo7hMKKHgo-GSFGpoXxsRG4",
  authDomain: "tools-evospike.firebaseapp.com",
  projectId: "tools-evospike",
  storageBucket: "tools-evospike.appspot.com",
  messagingSenderId: "1000867858730",
  appId: "1:1000867858730:web:6e81b85eb2f0adc3493653",
  measurementId: "G-WTJMD9G08R"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const analytics = getAnalytics(app)
const messaging = getMessaging()

const getTokenAsync = async () => {
  let currentToken = ""
  const { REACT_APP_VAPID_KEY } = process.env
  
  try {
    currentToken = await getToken(messaging, { vapidKey: REACT_APP_VAPID_KEY })

    if(currentToken) {
      // Send the token to your server and update the UI if necessary
    }
    else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }
  catch(err) {
    console.log('An error occurred while retrieving token. ', err);
  }

  return currentToken
}

const onMessageListenner = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
}

export { analytics, db, getTokenAsync, onMessageListenner, collection, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove }