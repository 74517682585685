import React, { useState, useContext } from "react";
import { BackdropContext } from "../contexts/BackdropContext"
import image from "../images/optimize_image.svg"
import Backdrop from "../components/Backdrop"
import ThumbnailFormContainer from "../containers/ThumbnailFormContainer"

function Emails() {
    const [backdropThumbnailForm, setBackdropThumbnailForm] = useState("")
    const { showBackdrop } = useContext(BackdropContext)

    return (
        <>
            <h1 className="evo-home-welcome-title">Thumbnails</h1>
            <div className="evo-home-welcome">
                <img alt="" className="evo-home-welcome-img" src={image} />
                <h2>Welcome to Thumbnails :-)</h2>
                <div className="evo-home-welcome-desc">¡Start exploring our image thumbnail tool!</div>
                <div className="evo-home-welcome-accions">
                    <button className="evo-btn evo-btn-primary" onClick={e => showBackdrop(backdropThumbnailForm, setBackdropThumbnailForm, e)}>Create thumbnail</button>
                    <a className="evo-btn evo-btn-primary" href="https://docs.evospike.com/en/latest/tools-evopsike/thumbnails/" target="_blank" rel="noreferrer">Documentation</a>
                </div>
            </div>
            <Backdrop
                title="Create image thumbnails"
                backdrop={backdropThumbnailForm}
                showBackdrop={e => showBackdrop(backdropThumbnailForm, setBackdropThumbnailForm, e)}
                bodyPresentation={<ThumbnailFormContainer backdrop={backdropThumbnailForm} />}
            /> 
        </>
    )
}

export default Emails;