import React, { createContext } from "react"

export const BackdropContext = createContext()

function BackdropProvider({ children }) {
    const showBackdrop = (backdrop, setBackdrop, e) => {
        if(backdrop !== "" && e.target !== e.currentTarget)
            return 

        backdrop === "" ? setBackdrop("show") : setBackdrop("")
    }

    return (
        <BackdropContext.Provider value={{ showBackdrop }}>
            {children}
        </BackdropContext.Provider>
    )
}

export default BackdropProvider