import React from "react"
import emailAction from "../actions/EmailAction"
import { Toaster } from 'react-hot-toast';

function EmailList({ accounts, dispatch, triggerShowBackdrop, onHandleSearch, onCopyApiKey, triggerShowBackdropEdit }) {
    return (
        <>
            <div className="evo-table-header">
                <div className="evo-table-header-item">
                    <h2>Emails</h2>
                </div>
                <div className="evo-table-header-item evo-table-header-item-search">
                    <i className="fas fa-search"></i>
                    <input type="search" className="evo-input-search" onChange={onHandleSearch} placeholder="Search" />
                </div>
                <div className="evo-table-header-item evo-table-header-item-last">
                    <button className="evo-btn evo-btn-primary" onClick={e => triggerShowBackdrop(e)}>Create email api key</button>
                    <a className="evo-btn evo-btn-primary" href="https://docs.evospike.com/en/latest/tools-evopsike/emails/" target="_blank" rel="noreferrer">Documentation</a>
                </div>
            </div>
            <div className="evo-table-container">
                <div className="evo-table-body">
                    <table>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Name</th>
                                <th>Api key</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                accounts?.map((account, index) => (
                                    <tr key={index}>
                                        <td>{account.email}</td>
                                        <td>{account.name}</td>
                                        <td><span>{account.apiKey}</span> <button className="evo-btn-badge" onClick={onCopyApiKey}>Copy</button></td>
                                        <td>
                                            <button className="evo-btn evo-btn-secondary" onClick={e => triggerShowBackdropEdit(account, e)}>Edit</button>
                                            <button className="evo-btn evo-btn-danger" onClick={() => dispatch({ type: emailAction.DELETE_ACCOUNT, payload: account })}>Delete</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Toaster
                position="top-right"
                toastOptions={{
                    duration: 2000,
                    style: {
                        background: '#333',
                        color: '#fff',
                        fontSize: "14px"
                      }
                }}
            />
        </>
    )
}

export default EmailList