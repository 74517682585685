import React from "react";
import logo from '../images/working_remotely.svg'
import twitter from '../images/Twitter.png'
import github from '../images/github.png'
import microsoft from '../images/microsoft.png'
import styles from '../styles/login.module.css'

function Login() {
    const redirect = window.location.pathname === "/login" ? "/" : window.location.pathname
    
    return(
        <div className="evo-row-flex">
            <div className={styles["evo-login-presentation"]}>
                <div className={styles["evo-login-title"]}>
                    <h2>Tools EvoSpike gives you potential tools to automate your business</h2>
                    <div>Create, test, publish and monitor your content quickly and securely</div>
                </div>
                <img src={logo} className={styles["evo-login-logo"]} width="100%" height="100%" alt=""></img>
                <div className="evo-login-principal"></div>
            </div>
            <div className={styles["evo-login-form"]}>
                <div className={styles["evo-login-form-container"]}>
                    <div>
                        <h2>Sign in</h2>
                    </div>
                    <div>
                        <p>Sign in to your account with one of these services</p>
                    </div>
                    <div className={styles["evo-login-form-btn-container"]}>
                        <a href={`/.auth/login/twitter?post_login_redirect_uri=${redirect}`} className={styles["evo-login-form-btn"]}>
                            <div className={styles["evo-login-form-btn-icon"]} style={{backgroundImage: `url(${twitter})`}}></div>
                            <span>Twitter</span>
                        </a>
                        <a href={`/.auth/login/github?post_login_redirect_uri=${redirect}`} className={styles["evo-login-form-btn"]}>
                            <div className={styles["evo-login-form-btn-icon"]} style={{backgroundImage: `url(${github})`}}></div>
                            <span>GitHub</span>
                        </a>
                        <a href={`/.auth/login/aad?post_login_redirect_uri=${redirect}`} className={styles["evo-login-form-btn"]}>
                            <div className={styles["evo-login-form-btn-icon"]} style={{backgroundImage: `url(${microsoft})`}}></div>
                            <span>Personal, Company or School</span>
                        </a>
                    </div>
                </div>
                <div>
                    <div className={styles["evo-login-copy-right"]}>
                        <span>&copy; 2021 EvoSpike</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login