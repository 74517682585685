import React from "react"
import VariantesOpcion from "./VariantesOpcion"
import VariantesTable from "./VariantesTable"

function VariantesForm({ inputRef, inputs, inputFileRef, handleFileChange, handleChange, handleFilePicker, removeImage, generateVariants, removeVariant, addCombinacionesImagenes, handleSelectUrl, webhookUrls, setBackdropVariantesForm }) {
    return (
        <form onSubmit={generateVariants} autoComplete="off">
            <div className="evo-form-row">
                <div className="evo-form-group">
                    <label>SKU:</label>
                    <input type="text" className="evo-form-input-text" ref={inputRef} name="tb_producId" value={inputs.tb_producId} onChange={handleChange} placeholder="P01" required />
                </div>
                <div className="evo-form-group">
                    <label>Quantity</label>
                    <input type="number" className="evo-form-input-text" name="tb_cantidad" value={inputs.tb_cantidad} onChange={handleChange} placeholder="0" required />
                </div>
            </div>
            <div className="evo-form-row">
                <div className="evo-form-group">
                    <label>Images</label>
                    <div className="evo-imagenes-container">
                        <div className="evo-imagenes-upload" onClick={handleFilePicker}>
                            <input type="file" name="imagenes" multiple hidden ref={inputFileRef} onChange={handleFileChange} />
                            <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                        {
                            inputs.blobUrls.map((blobUrl, index) => (
                                <div key={index} className="evo-imagenes-upload-file" style={{backgroundImage: `url(${blobUrl})`}}>
                                    <button title="Eliminar" onClick={() => removeImage(index)}>×</button>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="evo-form-row" style={{ marginTop: "-14px"}}>
                <VariantesOpcion {...{ label: "Option 1", nameSelect: "opcion1", valueSelect: inputs.opcion1, handleChange, nameInput: "tb_opciones1", valueInput: inputs.tb_opciones1 }} />
            </div>
            <div className="evo-form-row">
                <VariantesOpcion {...{ label: "Option 2", nameSelect: "opcion2", valueSelect: inputs.opcion2, handleChange, nameInput: "tb_opciones2", valueInput: inputs.tb_opciones2 }} />
            </div>
            <div className="evo-form-row">
                <VariantesOpcion {...{ label: "Option 3", nameSelect: "opcion3", valueSelect: inputs.opcion3, handleChange, nameInput: "tb_opciones3", valueInput: inputs.tb_opciones3 }} />
            </div>
            <div className="evo-form-row">
                <div className="evo-form-group">
                    <label>Webhook Url</label>
                    <select name="webhookUrl" className="evo-form-input-text" value={inputs.webhookUrl} onChange={handleSelectUrl} required>
                        <option value="" disabled="disabled" hidden="hidden">Choose an option</option>
                        {webhookUrls.length > 0 && webhookUrls.map((url, index) => (
                                <option key={index} value={url}>{url}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="evo-form-row">
                <div className="evo-form-group">
                    <button className="evo-btn evo-btn-primary" style={{ marginLeft: "0"}}>Generate variants</button>
                </div>
            </div>
            {inputs.combinaciones.length > 0 && <VariantesTable {...{ inputs, removeVariant, addCombinacionesImagenes, setBackdropVariantesForm }} />}
        </form>
    )
}

export default VariantesForm