import React, { useState, useEffect, useContext, useCallback } from "react";
import { db, doc, getDoc } from "../firebase-config"
import { UserContext } from "../contexts/UserContext"
import { BackdropContext } from "../contexts/BackdropContext"
import { EmailContext } from "../contexts/EmailContext"
import emailAction from "../actions/EmailAction"
import image from "../images/newslette.svg"
import Backdrop from "../components/Backdrop"
import EmailFormContainer from "../containers/EmailFormContainer"
import EmailListContainer from "../containers/EmailListContainer"

const initialState = {
    email: "",
    name: "",
    apiKey: ""
}

function Emails() {
    const userContext = useContext(UserContext)
    const { showBackdrop } = useContext(BackdropContext)
    const { accounts, dispatch } = useContext(EmailContext)
    const [account, setAccount] = useState(initialState)
    const [backdropEmailForm, setBackdropEmailForm] = useState("")
    const [backdropEmailFormEdit, setBackdropEmailFormEdit] = useState("")
    const [loading, setLoading] = useState(true)
    
    const getAccounts = useCallback(async () => {
        const docRef = doc(db, `accounts/${userContext?.userDetails}`)
        const docSnap = await getDoc(docRef)
        const accountsData = docSnap.data()
        
        dispatch({ type: emailAction.SET_ACCOUNT, payload: accountsData?.accounts || [] })
    }, [dispatch, userContext])

    useEffect(() => {
        (async () => {
            await getAccounts()
            setLoading(false)
        })()
    }, [getAccounts])

    useEffect(() => {
        setAccount(initialState)
    }, [backdropEmailForm])

    const onHandleChange = e => {
        setAccount({...account, [e.target.name]: e.target.value})
    }

    const onAddAccount = () => {
        dispatch({type: emailAction.ADD_ACCOUNT, payload: {...account, userId: userContext?.userDetails }})
        createVerifiedSender(account.name, account.email)
        setBackdropEmailForm("")
    }

    const createVerifiedSender = (nickname, from_email) => {
        const data = {
            nickname: nickname,
            from_email: from_email,
            reply_to: "info@evospike.com",
        }

        fetch("https://api.sendgrid.com/v3/verified_senders", { 
            method: "post", 
            body: JSON.stringify(data), 
            headers: { 
                'authorization': 'Bearer ' + process.env.REACT_APP_SENDGRID_KEY,
                'content-type': 'application/json'
            } 
        })
        .then(console.log)
        .catch(console.error)
    }

    const onUpdateAccount = () => {
        dispatch({ type: emailAction.UPDATE_ACCOUNT, payload: {...account} })
        setBackdropEmailFormEdit("")
    }

    const triggerShowBackdrop = e => {
        showBackdrop(backdropEmailForm, setBackdropEmailForm, e)
    }

    const triggerShowBackdropEdit = (account, e) => {
        setAccount({ ...account, accountToRemove: account })
        showBackdrop(backdropEmailFormEdit, setBackdropEmailFormEdit, e)
    }

    return (
        <>
            {loading === true && <p>Loading...</p>}
            {loading === false && accounts?.length === 0 &&
                <>
                    <h1 className="evo-home-welcome-title">Emails</h1>
                    <div className="evo-home-welcome">
                        <img alt="" className="evo-home-welcome-img" src={image} />
                        <h2>Welcome to Emails :-)</h2>
                        <div className="evo-home-welcome-desc">¡Start exploring our personalized email sending tool!</div>
                        <div className="evo-home-welcome-accions">
                            <button className="evo-btn evo-btn-primary" onClick={e => showBackdrop(backdropEmailForm, setBackdropEmailForm, e)}>Create email api key</button>
                            <a className="evo-btn evo-btn-primary" href="https://docs.evospike.com/en/latest/tools-evopsike/emails/" target="_blank" rel="noreferrer">Documentation</a>
                        </div>
                    </div>
                </>
            }
            {loading === false && accounts?.length > 0 && <EmailListContainer triggerShowBackdrop={triggerShowBackdrop} triggerShowBackdropEdit={triggerShowBackdropEdit} />}
            <Backdrop
                title="Add new email"
                backdrop={backdropEmailForm}
                showBackdrop={e => showBackdrop(backdropEmailForm, setBackdropEmailForm, e)}
                bodyPresentation={<EmailFormContainer backdrop={backdropEmailForm} account={account} onHandleChange={onHandleChange} />} 
                btnText={"Add"}
                onClick={onAddAccount}
            />
             <Backdrop
                title="Edit email"
                backdrop={backdropEmailFormEdit}
                showBackdrop={e => showBackdrop(backdropEmailFormEdit, setBackdropEmailFormEdit, e)}
                bodyPresentation={<EmailFormContainer backdrop={backdropEmailFormEdit} account={account} onHandleChange={onHandleChange} />} 
                btnText={"Update"}
                onClick={onUpdateAccount}
            /> 
        </>
    )
}

export default Emails;