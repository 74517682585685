import React from "react";
import image from "../images/web_search.svg"

function NotFound() {
    return (
        <>
            <h2 className="evo-home-welcome-title">404 Not Found</h2>
            <div className="evo-home-welcome">
                <img alt="" className="evo-home-welcome-img" src={image} />
                <h2>Page not found :-(</h2>
                <div className="evo-home-welcome-desc">¡The page you are looking for does not exist or another error occurred!</div>
            </div>
        </>
    )
}

export default NotFound;