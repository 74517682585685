import React from "react"
import toast from 'react-hot-toast';
import NotificacionesInfo from "../components/NotificacionesInfo"

function NotificacionesInfoContainer() {
    const notificationConfig = {
        apiKey: "AIzaSyA2tqmJrSbKOo7hMKKHgo-GSFGpoXxsRG4",
        projectId: "tools-evospike",
        messagingSenderId: "1000867858730",
        appId: "1:1000867858730:web:6e81b85eb2f0adc3493653",
        vapidKey: "BJFfv8_SFe71o0p9QpswIl0xZo1ITpjSg_vasw0hxI_Du5xg5YHXdho244hppEJNA5YVA_ee15kBVb8XPl341Uw"
    }

    const onCopyNotificationConfig = () => {
        navigator.clipboard.writeText(JSON.stringify(notificationConfig)).then(() => {
            notify()
        })
    }

    const notify = () => toast.success('Copy to clipboard successfully')

    return <NotificacionesInfo {...{onCopyNotificationConfig}}/>
}

export default NotificacionesInfoContainer