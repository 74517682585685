import React from "react"

function EmailForm({ inputRef, account, onHandleChange }) {
    return (
        <form onSubmit={e => e.preventDefault()} autoComplete="off">
             <div className="evo-form-row">
                <div className="evo-form-group">
                    <label>Email</label>
                    <input 
                        type="text" 
                        className="evo-form-input-text" 
                        ref={inputRef} 
                        name="email" 
                        value={account.email}
                        onChange={onHandleChange} 
                        placeholder="test@dominio.com" 
                        required 
                    />
                </div>
            </div>
            <div className="evo-form-row">
                <div className="evo-form-group">
                    <label>Name</label>
                    <input 
                        type="text" 
                        className="evo-form-input-text"
                        name="name" 
                        value={account.name}
                        onChange={onHandleChange} 
                        placeholder="ana meliza" 
                        required 
                    />
                </div>
            </div>
        </form>
    )
}

export default EmailForm