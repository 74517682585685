import React, { useContext } from "react"
import image from "../images/organizing_projects.svg"
import { UserContext } from "../contexts/UserContext"

function Home() {
    const userContext = useContext(UserContext)

    return (
        <>
            <h1 className="evo-home-welcome-title">Hello, {userContext?.userDetails}</h1>
            <div className="evo-home-welcome">
                <img alt="" className="evo-home-welcome-img" src={image} />
                <h2>Welcome, {userContext?.userDetails} :-)</h2>
                <div className="evo-home-welcome-desc">¡Start exploring our tools with the menu on the left!</div>
                <div className="evo-home-welcome-desc" style={{width: "80%", maxWidth: "400px"}}>The first step is to create a webhook which is a url that points to your system, as they are used by almost all tools to send information in JSON format to your system.</div>
            </div>
        </>
    );
}

export default Home;