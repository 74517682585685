import React, { useContext, useEffect, useState } from "react"
import toast from 'react-hot-toast';
import { EmailContext } from "../contexts/EmailContext"
import EmailList from "../components/EmailList"

function EmailListContainer({ triggerShowBackdrop, triggerShowBackdropEdit }) {
    const { accounts, dispatch } = useContext(EmailContext)
    const [accountsSearch, setAccountsSearch] = useState(accounts)
    
    useEffect(() => {
        setAccountsSearch(accounts)
    }, [accounts])

    const onHandleSearch = e => {
        const { value } = e.target
        setAccountsSearch(accounts.filter(account => Object.values(account).some(v => v.includes(value))))
    }

    const onCopyApiKey = e => {
        navigator.clipboard.writeText(e.target.previousElementSibling.textContent).then(() => {
            notify()
        })
    }

    const notify = () => toast.success('Copiado al portapapeles con éxito')

    return <EmailList accounts={accountsSearch} {...{dispatch, triggerShowBackdrop, onHandleSearch, onCopyApiKey, triggerShowBackdropEdit}} />
}

export default EmailListContainer