import React, { useState, useContext } from "react";
import { BackdropContext } from "../contexts/BackdropContext"
import Backdrop from "../components/Backdrop"
import image from "../images/push_notifications.svg"
import NotificacionesInfoContainer from "../containers/NotificacionesInfoContainer"

function Notificaciones() {
    const [backdropNotificaciones, setBackdropNotificaciones] = useState("")
    const { showBackdrop } = useContext(BackdropContext)

    return (
        <>
            <h1 className="evo-home-welcome-title">Notifications</h1>
            <div className="evo-home-welcome">
                <img alt="" className="evo-home-welcome-img" src={image} />
                <h2>Welcome to Push notifications :-)</h2>
                <div className="evo-home-welcome-desc">¡Start exploring our push notification tool</div>
                <div className="evo-home-welcome-accions">
                    <button className="evo-btn evo-btn-primary" onClick={e => showBackdrop(backdropNotificaciones, setBackdropNotificaciones, e)}>Create push notification</button>
                    <a className="evo-btn evo-btn-primary" href="https://docs.evospike.com/en/latest/tools-evopsike/push-notifications/" target="_blank" rel="noreferrer">Documentation</a>
                </div>
            </div>
            <Backdrop
                title="Create push notification"
                backdrop={backdropNotificaciones}
                showBackdrop={e => showBackdrop(backdropNotificaciones, setBackdropNotificaciones, e)}
                bodyPresentation={<NotificacionesInfoContainer />}
            /> 
        </>
    )
}

export default Notificaciones;