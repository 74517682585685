import React from "react"
import { Toaster } from 'react-hot-toast';

function NotificacionesInfo({ onCopyNotificationConfig }) {
    return (
        <>
        <div className="evo-form-row">
            <div className="evo-form-group">
                <div className="evo-code-box-body">
                    <p>Copy the content by pressing the <b>copy</b> button and save it to your project</p>
                    <code>
                        {'{\n\n'}
                            &nbsp;&nbsp;{'apiKey: "AIzaSyA2tqmJrSbKOo7hMKKHgo-GSFGpoXxsRG4",\n'}
                            &nbsp;&nbsp;{'projectId: "tools-evospike",\n'}
                            &nbsp;&nbsp;{'messagingSenderId: "1000867858730",\n'}
                            &nbsp;&nbsp;{'appId: "1:1000867858730:web:6e81b85eb2f0adc3493653"\n'}
                            &nbsp;&nbsp;{'vapidKey: "BJFfv8_SFe71o0p9QpswIl0xZo1ITpjSg..."\n\n'}
                        {'}'}
                    </code>
                </div>
            </div>
        </div>
        <div className="evo-form-row">
            <div className="evo-form-group">
                <button className="evo-btn evo-btn-primary" style={{ marginLeft: "0"}} onClick={onCopyNotificationConfig}>Copy</button>
            </div>
        </div>
        <div className="evo-form-row">
            <div className="evo-form-group">
                <p>After saving the information go to the documentation section and choose your environment</p>
                <p>then follow the steps to be able to receive and send notifications</p>
            </div>
        </div>
         <Toaster
                position="top-right"
                toastOptions={{
                    duration: 2000,
                    style: {
                        background: '#333',
                        color: '#fff',
                        fontSize: "14px"
                      }
                }}
            />
        </>
    )
}

export default NotificacionesInfo