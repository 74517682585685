import React from "react"
import styles from "../styles/layaout.module.css"
import HeaderContainer from "../containers/HeaderContainer"
import AsideContainer from "../containers/AsideContainer"
import { Outlet } from "react-router-dom"

function Layout() {
    return (
        <>
            <HeaderContainer />
            <main className={styles["evo-layaout-container"]}>
                <AsideContainer />
                <section className={styles["evo-layout-section"]}>
                    <div className={styles["evo-layout-section-box"]}>
                        <Outlet />
                    </div>
                </section>
            </main>
        </>
    )
}

export default Layout