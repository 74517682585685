import React, { useState } from "react"
import { Link } from "react-router-dom";

function Header({ styles, letters, logo, backdrop, showBackdrop, userContext }) {
    const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem("darkmode")) || true)
    
    const changeTheme = () => {
        document.body.classList.toggle("evo-light-mode")
        localStorage.setItem("darkmode", !darkMode)
        setDarkMode(!darkMode)
    }

    return (
        <>
            <header className={styles["evo-navbar-header"]}>
                <nav className={styles["evo-navbar"]}>
                    <Link className={styles["evo-navbar-brand"]} to="/">
                        <div className={styles["evo-navbar-brand-logo"]} style={{backgroundImage: `url(${logo})`}}></div>
                    </Link>
                    <button className={styles["evo-navbar-btn"]} onClick={changeTheme}>
                        <span>Dark mode</span>
                        {darkMode ? <i className="fas fa-toggle-on"></i> : <i className="fas fa-toggle-off"></i>}
                    </button>
                    <button className={styles["evo-navbar-btn-account"]} onClick={showBackdrop}>
                        <div>
                            <span>{letters}</span>
                        </div>
                    </button>
                </nav>
            </header>
            <div className={`evo-overlay-backdrop ${backdrop}`} onClick={showBackdrop}>
                <div className={styles["evo-user-menu"]}>
                    <div className={styles["evo-user-info"]}>
                        <div className={styles["evo-user-info-box"]}>
                            <div className={styles["evo-user-info-icon"]}>
                                <span>{letters}</span>
                            </div>
                            <div className={styles["evo-user-info-desc"]}>
                                <span className={styles["evo-user-info-text-primary"]} title={userContext?.userDetails}>{userContext?.userDetails}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles["evo-list-item"]}>
                        <div className={styles["evo-list-item-box"]}>
                            <a href="/.auth/logout?post_logout_redirect_uri=/login" className={styles["evo-menu-item"]}><i className="fas fa-sign-out-alt"></i>Log out</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header