import React, { createContext, useReducer } from "react"
import emailReducer from "../reducers/EmailReducer"

export const EmailContext = createContext()
const initialState = {
    accounts: []
}

function EmailProvider({ children }) {
    const [state, dispatch] = useReducer(emailReducer, initialState)

    return (
        <EmailContext.Provider value={{ ...state, dispatch }}>
            {children}
        </EmailContext.Provider>
    )
}

export default EmailProvider