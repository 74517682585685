import React, { useEffect, useRef } from "react"
import WebhookForm from "../components/WebhookForm"

function WebhookFormContainer({ backdrop, webhook, onHandleChange }) {
    const inputRef = useRef()

    useEffect(() => {
        if(backdrop) {
            inputRef.current.focus()
        }
    }, [backdrop])

    return <WebhookForm inputRef={inputRef} webhook={webhook} onHandleChange={onHandleChange} />
}

export default WebhookFormContainer