import React, { useState, useEffect, useContext, useCallback } from "react"
import { db, doc, getDoc } from "../firebase-config"
import { UserContext } from "../contexts/UserContext"
import { BackdropContext } from "../contexts/BackdropContext"
import { WebhookContext } from "../contexts/WebhookContext"
import webhookAction from "../actions/WebhookAction"
import image from "../images/tutorial_video.svg"
import Backdrop from "../components/Backdrop"
import WebhookFormContainer from "../containers/WebhookFormContainer"
import WebhookListContainer from "../containers/WebhookListContainer"

const initialState = {
    webhookUrl: ""
}

function Webhooks() {
    const userContext = useContext(UserContext)
    const { showBackdrop } = useContext(BackdropContext)
    const { webhookUrls, dispatch } = useContext(WebhookContext)
    const [webhook, setWebhook] = useState(initialState)
    const [backdropWebhookForm, setBackdropWebhookForm] = useState("")
    const [backdropWebhookFormEdit, setBackdropWebhookFormEdit] = useState("")
    const [loading, setLoading] = useState(true)
    
    const getWebhooks = useCallback(async () => {
        const docRef = doc(db, `webhookds/${userContext?.userDetails}`)
        const docSnap = await getDoc(docRef)
        const webhooksData = docSnap.data()
        
        dispatch({ type: webhookAction.SET_WEBHOOK, payload: webhooksData?.webhookUrls || [] })
    }, [dispatch, userContext])

    useEffect(() => {
        (async () => {
            await getWebhooks()
            setLoading(false)
        })()
    }, [getWebhooks])

    useEffect(() => {
        setWebhook(initialState)
    }, [backdropWebhookForm])

    const onHandleChange = e => {
        setWebhook({...webhook, [e.target.name]: e.target.value})
    }

    const onAddWebhook = () => {
        dispatch({type: webhookAction.ADD_WEBHOOK, payload: {...webhook, userId: userContext?.userDetails }})
        setBackdropWebhookForm("")
    }

    const onUpdateWebhook = () => {
        dispatch({ type: webhookAction.UPDATE_WEBHOOK, payload: {...webhook, userId: userContext?.userDetails} })
        setBackdropWebhookFormEdit("")
    }

    const triggerShowBackdrop = e => {
        showBackdrop(backdropWebhookForm, setBackdropWebhookForm, e)
    }

    const triggerShowBackdropEdit = (webhookUrl, e) => {
        setWebhook({ webhookUrl: webhookUrl, webhookUrlToRemove: webhookUrl })
        showBackdrop(backdropWebhookFormEdit, setBackdropWebhookFormEdit, e)
    }

    return (
        <>
            {loading === true && <p>Loading...</p>}
            {loading === false && webhookUrls?.length === 0 &&
                <>
                    <h1 className="evo-home-welcome-title">Webhooks</h1>
                    <div className="evo-home-welcome">
                        <img alt="" className="evo-home-welcome-img" src={image} />
                        <h2>Welcome to Webhooks :-)</h2>
                        <div className="evo-home-welcome-desc">¡Start exploring our webhooks tool!</div>
                        <div className="evo-home-welcome-accions">
                            <button className="evo-btn evo-btn-primary" onClick={e => showBackdrop(backdropWebhookForm, setBackdropWebhookForm, e)}>Create webhook</button>
                            <a className="evo-btn evo-btn-primary" href="https://docs.evospike.com/en/latest/tools-evopsike/webhooks/" target="_blank" rel="noreferrer">Documentation</a>
                        </div>
                    </div>
                </>
            }
            {loading === false && webhookUrls?.length > 0 && <WebhookListContainer triggerShowBackdrop={triggerShowBackdrop} triggerShowBackdropEdit={triggerShowBackdropEdit} />}
            <Backdrop
                title="Add new webhook"
                backdrop={backdropWebhookForm}
                showBackdrop={e => showBackdrop(backdropWebhookForm, setBackdropWebhookForm, e)}
                bodyPresentation={<WebhookFormContainer backdrop={backdropWebhookForm} webhook={webhook} onHandleChange={onHandleChange} />} 
                btnText={"Add"}
                onClick={onAddWebhook}
            /> 
            <Backdrop
                title="Edit webhook"
                backdrop={backdropWebhookFormEdit}
                showBackdrop={e => showBackdrop(backdropWebhookFormEdit, setBackdropWebhookFormEdit, e)}
                bodyPresentation={<WebhookFormContainer backdrop={backdropWebhookFormEdit} webhook={webhook} onHandleChange={onHandleChange} />} 
                btnText={"Update"}
                onClick={onUpdateWebhook}
            /> 
        </>
    )
}

export default Webhooks;