import React, { useState, useContext } from "react"
import styles from "../styles/header.module.css"
import logo from "../images/evospike-logo.jpeg"
import { UserContext } from "../contexts/UserContext"
import Header from "../components/Header"

function HeaderContainer() {
    let letters = ""
    const userContext = useContext(UserContext)
    const [backdrop, setBackdrop] = useState("")

    if(userContext?.userDetails !== undefined) {
        const infoSeparate =  userContext.userDetails.split(" ")
        letters = infoSeparate[0].substring(0, 1).toUpperCase()

        if(infoSeparate.length > 2) {
            letters += infoSeparate[2].substring(0, 1).toUpperCase()
        }
    }

    const showBackdrop = e => {
        if(backdrop !== "" && e.target !== e.currentTarget)
            return 

        backdrop === "" ? setBackdrop("show") : setBackdrop("")
    }
    
    return <Header {...{ styles, letters, logo, backdrop, showBackdrop, userContext }} />
}

export default HeaderContainer