import React, { useEffect, useRef } from "react"
import EmailForm from "../components/EmailForm"

function EmailFormContainer({ backdrop, account, onHandleChange }) {
    const inputRef = useRef()

    useEffect(() => {
        if(backdrop) {
            inputRef.current.focus()
        }
    }, [backdrop])

    return <EmailForm inputRef={inputRef} account={account} onHandleChange={onHandleChange} />
}

export default EmailFormContainer