import React, { createContext, useState, useEffect } from "react"

export const UserContext = createContext()

function UserProvider({children}) {
    const [userInfo, setUserInfo] = useState("")
    
    useEffect(() => {
        (async () => {
          setUserInfo(await getUserInfo())
        })()
    }, [])
  
    async function getUserInfo() {
        try {
            const response = await fetch('/.auth/me')
            const payload = await response.json()
            const { clientPrincipal } = payload
            return clientPrincipal
        } 
        catch (error) {
            console.error('No profile could be found')
            return undefined
        }
    }

    return (
        <UserContext.Provider value={userInfo}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider