import { db, doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove } from "../firebase-config"
import webhookAction from "../actions/WebhookAction"

const webhookReducer = (state, action) => {
    switch(action.type) {
        case webhookAction.SET_WEBHOOK:
            return { webhookUrls: action.payload }
        case webhookAction.ADD_WEBHOOK:
            addWebhook(action.payload)
            return { webhookUrls: [...state.webhookUrls, action.payload.webhookUrl] }
        case webhookAction.UPDATE_WEBHOOK:
            updateWebhook(action.payload)
            return { webhookUrls: [...state.webhookUrls.filter(url => url !== action.payload.webhookUrlToRemove), action.payload.webhookUrl] }
        case webhookAction.DELETE_WEBHOOK:
            deleteWebhook(action.payload)
            return { webhookUrls: [...state.webhookUrls.filter(url => url !== action.payload.webhookUrl)] }
        default:
            return state
    }
}

const addWebhook = async (payload) => {
    const docRef = doc(db, `webhookds/${payload.userId}`)
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()) {
        await updateDoc(docRef, {
            webhookUrls: arrayUnion(payload.webhookUrl)
        })
    }
    else {
        await setDoc(docRef, { webhookUrls: [payload.webhookUrl] })
    }
}

const updateWebhook = async (payload) => {
    const docRef = doc(db, `webhookds/${payload.userId}`)
    
    await updateDoc(docRef, {
        webhookUrls: arrayRemove(payload.webhookUrlToRemove)
    })

    await updateDoc(docRef, {
        webhookUrls: arrayUnion(payload.webhookUrl)
    })
}

const deleteWebhook = async (payload) => {
    const docRef = doc(db, `webhookds/${payload.userId}`)
    const docSnap = await getDoc(docRef);
    
    if(docSnap.exists()) {
        await updateDoc(docRef, {
            webhookUrls: arrayRemove(payload.webhookUrl)
        })
    }
}

export default webhookReducer