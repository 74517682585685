import React from "react"
import { Toaster } from 'react-hot-toast';

function ThumbnailForm({ thumbnails, inputFileRef, handleFilePicker, handleFileChange, downloadImages }) {
    return (
        <form onSubmit={e => e.preventDefault()} autoComplete="off">
            <div className="evo-form-row">
                <div className="evo-form-group">
                    <label>Upload images</label>
                    <div className="evo-imagenes-container">
                        <div className="evo-imagenes-upload evo-imagenes-thumbnails" onClick={handleFilePicker}>
                            <input type="file" name="imagenes" multiple hidden accept=".png,.jpg,.jpeg" ref={inputFileRef} onChange={handleFileChange} />
                            <span>Clic to upload</span>
                            <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
            {thumbnails.length > 0 &&
            <>
                <div className="evo-form-row">
                    <div className="evo-form-group">
                        <label>Generated thumbnails {thumbnails.length} of 10</label>
                        <div className="evo-imagenes-container evo-imagenes-thumb-generated">
                            {thumbnails.map((blobUrl, index) => <div key={index} className="evo-imagenes-upload-file evo-imagenes-thumbnails-file" style={{backgroundImage: `url(${blobUrl})`}}></div>)}
                        </div>
                    </div>
                </div>
                <div className="evo-form-row">
                    <div className="evo-form-group">
                        <button className="evo-btn evo-btn-primary" style={{ marginLeft: "0"}} onClick={downloadImages}>Download</button>
                    </div>
                </div>
            </>}
            <Toaster
                position="top-right"
                toastOptions={{
                    duration: 2000,
                    style: {
                        background: '#333',
                        color: '#fff',
                        fontSize: "14px"
                      }
                }}
            />
        </form>
    )
}

export default ThumbnailForm