import { db, doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove } from "../firebase-config"
import { v4 as uuidv4 } from 'uuid';
import emailAction from "../actions/EmailAction"

const emailReducer = (state, action) => {
    switch(action.type) {
        case emailAction.SET_ACCOUNT:
            return { accounts: action.payload }
        case emailAction.ADD_ACCOUNT:
            action.payload.apiKey = uuidv4()
            addAccount(action.payload)
            return { accounts: [...state.accounts, action.payload] }
        case emailAction.UPDATE_ACCOUNT:
            updateAccount(action.payload)
            const apiKey = action.payload.accountToRemove.apiKey
            delete action.payload.accountToRemove
            return { accounts: [...state.accounts.filter(account => account.apiKey !== apiKey), action.payload] }
        case emailAction.DELETE_ACCOUNT:
            deleteAccount(action.payload)
            return { accounts: [...state.accounts.filter(account => account.apiKey !== action.payload.apiKey)] }
        default:
            return state
    }
}

const addAccount = async (payload) => {
    const docRef = doc(db, `accounts/${payload.userId}`)
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()) {
        await updateDoc(docRef, {
            accounts: arrayUnion(payload)
        })
    }
    else {
        await setDoc(docRef, { accounts: [payload] })
    }
}

const updateAccount = async (payload) => {
    const docRef = doc(db, `accounts/${payload.userId}`)
    
    await updateDoc(docRef, {
        accounts: arrayRemove(payload.accountToRemove)
    })

    await updateDoc(docRef, {
        accounts: arrayUnion(payload)
    })
}

const deleteAccount = async (payload) => {
    const docRef = doc(db, `accounts/${payload.userId}`)
    const docSnap = await getDoc(docRef);
    
    if(docSnap.exists()) {
        await updateDoc(docRef, {
            accounts: arrayRemove(payload)
        })
    }
}

export default emailReducer