import React, { useState, useEffect, useRef, useCallback, useContext } from "react"
import { db, doc, getDoc } from "../firebase-config"
import { UserContext } from "../contexts/UserContext"
import VariantesForm from "../components/VariantesForm"

const initialValues = {
    tb_producId: "",
    tb_cantidad: "",
    opcion1: "",
    tb_opciones1: "",
    opcion2: "",
    tb_opciones2: "",
    opcion3: "",
    tb_opciones3: "",
    imagenes: [],
    blobUrls: [],
    combinaciones: [],
    combinacionesImagenes: [],
    variantes: [],
    webhookUrl: ""
}

function VariantesFormContainer({ backdrop, setBackdropVariantesForm }) {
    const userContext = useContext(UserContext)
    const [inputs, setInputs] = useState(initialValues)
    const [webhookUrls, setWebhookUrls] = useState([])
    const inputRef = useRef()
    const inputFileRef = useRef()
    
    const getWebhooks = useCallback(async () => {
        const docRef = doc(db, `webhookds/${userContext?.userDetails}`)
        const docSnap = await getDoc(docRef)
        const webhooksData = docSnap.data()

        setWebhookUrls(webhooksData?.webhookUrls || [])
    }, [userContext])

    useEffect(() => {
        (async () => {
            await getWebhooks()
        })()
    }, [getWebhooks])

    useEffect(() => {
        if(backdrop) {
            inputRef.current.focus()
            setInputs(initialValues)
        }
    }, [backdrop])

    const handleSelectUrl = e => {
        const {name, value} = e.target
        setInputs(values => ({...values, [name]: value}))
    }

    const handleChange = e => {
        const {name, value} = e.target
        setInputs(values => ({...values, [name]: value}))
    }

    const handleFileChange = e => {
        for(const file of e.target.files)
        {
            const blobUrl = window.URL.createObjectURL(file)
            setInputs(values => ({...values, "imagenes": [...values.imagenes, file.name], "blobUrls": [...values.blobUrls, blobUrl]}))
        }
    }

    const handleFilePicker = () => {
        inputFileRef.current.click()
    }

    const addCombinacionesImagenes = (index, combinacionKey) => {
        setInputs(values => {
            const imageToAdd = values.imagenes[index]
            const blobToAdd = values.blobUrls[index]
            const combinacionToAdd = values.combinaciones[combinacionKey]
            return {
                ...values, 
                "combinacionesImagenes": [...values.combinacionesImagenes.filter(c => c.combinacionKey !== combinacionKey), { imagen: imageToAdd, blobUrl: blobToAdd, combinacion: combinacionToAdd, combinacionKey }]
            }
        })
    }

    const removeImage = (index) => {
        setInputs(values => { 
            const imageToRemove = values.imagenes[index]
            const blobToRemove = values.blobUrls[index]
            return {
                ...values, 
                "imagenes": values.imagenes.filter(i => i !== imageToRemove), 
                "blobUrls": values.blobUrls.filter(b => b !== blobToRemove)
            }
        })
    }

    const removeVariant = (index) => {
        setInputs(values => { 
            const variantoRemove = values.combinaciones[index]
            const filter = values.combinacionesImagenes.filter(c => c.combinacionKey !== index).map(c => {
                const key = c.combinacionKey - 1
                if(c.combinacionKey > index) {
                   return {...c, "combinacionKey": key}
                }
                
                return c
            })

            return {
                ...values, 
                "combinaciones": values.combinaciones.filter(c => c !== variantoRemove),
                "combinacionesImagenes": filter
            }
        })
    }

    const generateVariants = e => {
        e.preventDefault()
        const caracteristicas = new Map()
        
        for(let i = 1; i <= 3; i++) {
            let selectValue = inputs[`opcion${i}`]
            let inputValue = inputs[`tb_opciones${i}`]

            if(selectValue && inputValue) {
                caracteristicas.set(selectValue, new Set())

                for (let variante of inputValue.split(",")) {
                    caracteristicas.get(selectValue).add(capitalizeFirstLetter(variante));
                }
            }
        }

        if(caracteristicas.size > 0) {
            let conjuntos = [[], [], []]
            let conteo = 0

            for (let clave of caracteristicas.keys()) {
                conjuntos[conteo++] = Array.from(caracteristicas.get(clave))
            }

            let combinaciones = []
            let caracteristicasArray = Array.from(caracteristicas.keys())
            for (let conjunto1 of conjuntos[0]) {
                let combinacion = ""
                combinacion += conjunto1

                if (conjuntos[1].length === 0) {
                    combinaciones.push([{AtributoNombre: caracteristicasArray[0], ValorId: combinacion}])
                }
                else {
                    for (let conjunto2 of conjuntos[1]) {
                        if (conjuntos[2].length === 0) {
                            combinaciones.push([
                                {AtributoNombre: caracteristicasArray[0], ValorId: combinacion},
                                {AtributoNombre: caracteristicasArray[1], ValorId: conjunto2}
                            ])
                        }
                        else {
                            for (let conjunto3 of conjuntos[2]) {
                                combinaciones.push([
                                    {AtributoNombre: caracteristicasArray[0], ValorId: combinacion},
                                    {AtributoNombre: caracteristicasArray[1], ValorId: conjunto2},
                                    {AtributoNombre: caracteristicasArray[2], ValorId: conjunto3}
                                ])
                            }
                        }
                    }
                }
            }
            setInputs(values => ({...values, "combinaciones": combinaciones}))
        }
        else {
            alert("You must provide at least one option")
        }
    }

    const capitalizeFirstLetter = value => value.trim().charAt(0).toUpperCase() + value.trim().slice(1)

    return <VariantesForm {...{ inputRef, inputs, inputFileRef, handleFileChange, handleChange, handleFilePicker, removeImage, generateVariants, removeVariant, addCombinacionesImagenes, handleSelectUrl, webhookUrls, setBackdropVariantesForm }} />
}

export default VariantesFormContainer