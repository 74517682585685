import React, { useContext, useEffect, useState } from "react"
import { WebhookContext } from "../contexts/WebhookContext"
import WebhookList from "../components/WebhookList"
import { UserContext } from "../contexts/UserContext"

function WebhookListContainer({ triggerShowBackdrop, triggerShowBackdropEdit }) {
    const userContext = useContext(UserContext)
    const { webhookUrls, dispatch } = useContext(WebhookContext)
    const [webhookUrlsSearch, setWebhookUrlsSearch] = useState(webhookUrls)
    
    useEffect(() => {
        setWebhookUrlsSearch(webhookUrls)
    }, [webhookUrls])

    const onHandleSearch = e => {
        const { value } = e.target
        setWebhookUrlsSearch(webhookUrls.filter(url => url.includes(value)))
    }

    return <WebhookList webhookUrls={webhookUrlsSearch} {...{dispatch, triggerShowBackdrop, onHandleSearch, userContext, triggerShowBackdropEdit}} />
}

export default WebhookListContainer