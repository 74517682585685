import React, { createContext, useReducer } from "react"
import webhookReducer from "../reducers/WebhookReducer"

export const WebhookContext = createContext()
const initialState = {
    webhookUrls: []
}

function WebhookProvider({ children }) {
    const [state, dispatch] = useReducer(webhookReducer, initialState)

    return (
        <WebhookContext.Provider value={{ ...state, dispatch }}>
            {children}
        </WebhookContext.Provider>
    )
}

export default WebhookProvider