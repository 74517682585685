import React, { useState, useRef, useEffect } from "react"
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import ThumbnailForm from "../components/ThumbnailForm"

function ThumbnailFormContainer({ backdrop }) {
    const [thumbnails, setThumbnails] = useState([])
    const inputFileRef = useRef()

    useEffect(() => {
        if(backdrop) {
            setThumbnails([])
        }
    }, [backdrop])

    const handleFilePicker = () => {
        inputFileRef.current.click()
    }

    const handleFileChange = e => {
        setThumbnails([])

        let count = 1;
        for(const file of e.target.files)
        {
            if(count++ >= 11) return;

            generateThumbnail(file, [200, 200]).then(function(dataUrl){
                setThumbnails(values => [...values, dataUrl])
            })
        }
    }

    const downloadImages = () => {
        for(var thumbnail of thumbnails) {
            var a = document.createElement('a')
            a.href = thumbnail
            a.download = uuidv4() + ".png"
            document.body.appendChild(a)
            a.click()
            a.remove()
        }
        
        notify()
    }

    const notify = () => toast.success('Successfully downloaded images')

    return <ThumbnailForm {...{thumbnails, inputFileRef, handleFilePicker, handleFileChange, downloadImages }} />
}

function generateThumbnail(file, boundBox) {
    if (!boundBox || boundBox.length !== 2){
      console.error("You need to give the boundBox")
      return "";
    }
    
    var reader = new FileReader();
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext('2d');
  
    return new Promise((resolve, reject) => {
      reader.onload = function(event){
          var img = new Image();
          img.onload = function(){
              var scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
              let w = img.width*scaleRatio
              let h = img.height*scaleRatio
              canvas.width = w;
              canvas.height = h;
              ctx.drawImage(img, 0, 0, w, h);
              return resolve(canvas.toDataURL(file.type))
          }
          img.src = event.target.result;
      }
      reader.readAsDataURL(file);
    })
  }

export default ThumbnailFormContainer