import React from "react"

function VariantesOpcion({ label, nameSelect, valueSelect, handleChange, nameInput, valueInput }) {
    return (
        <>
            <div className="evo-form-group">
                <label>{label}</label>
                <select name={nameSelect} className="evo-form-input-text" value={valueSelect} onChange={handleChange}>
                    <option value="" disabled="disabled" hidden="hidden">Choose an option</option>
                    <option value="Size">Size</option>
                    <option value="Color">Color</option>
                    <option value="Material">Material</option>
                    <option value="Estilo">Style</option>
                    <option value="Título">Title</option>
                </select>
            </div>
            <div className="evo-form-group">
                <label>Options</label>
                <input type="text" className="evo-form-input-text" name={nameInput} value={valueInput} onChange={handleChange} placeholder="separate by comma" />
            </div>
        </>
    )
}

export default VariantesOpcion