import React, { useState, useEffect } from "react"

function VariantesImages({ imagenes, onCombinacionSelected, modalVariantesImages }) {
    const [select, setselect] = useState("")
    
    const toggleSelect = (index) => {
        setselect(index)
        onCombinacionSelected(index)
    }

    useEffect(() => {
        if(modalVariantesImages) {
            setselect("")
        }
    }, [modalVariantesImages])

    return (
        <div className="evo-modal-imagenes-container">
            {
                imagenes.map((blobUrl, index) => (
                    <div 
                        key={index} 
                        className={`evo-imagenes-upload-file ${index === select ? "selected" : ""}`}
                        style={{backgroundImage: `url(${blobUrl})`}}
                        onClick={() => toggleSelect(index)}>
                    </div>
                ))
            }
        </div>
    )
}

export default VariantesImages